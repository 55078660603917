import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { UserSessionSelector } from 'store/UserSession';

import { consultantAnalyzerSelector } from 'store/Consultant/ConsultantAnalyser';
import {
  fetchClientOrderDisplay,
  fetchClientTextOrderDisplay,
} from 'store/Client/OrderDisplay/features';

import { DownloadOneTextWordType } from 'components/shared/DownloadFile/DownloadTextWordType';
import { RevisionDetails } from 'components/shared/RevisionDetails';

import { ANALYSELANGAUGESLIST } from 'utils/Constants';

import { Loader } from 'components/shared/Loader';
import { CustomTooltip } from 'components/shared/CustomTooltip';
import { CustomHighcharts } from 'components/shared/CustomHighcharts';
import { CustomEditor } from 'components/shared/CustomEditor';
import { capitalize } from 'helpers/Capitalize';
import { replaceAmpersands } from 'utils/StringUtils';
import { ChevronDownIcon } from 'icons/ChevronIcon';
import {
  formatKeywordData,
  extractKeywordsWithFrequency,
  transformData,
  transformToSemanticGap,
} from 'helpers/FormatKeywordsHelper';

function ClientTextDisplay() {
  const { textForm, orderType, orderId, isLoading, isError, textType } =
    useSelector(consultantAnalyzerSelector);
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector(UserSessionSelector);
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  const [textTitle, setTextTitle] = useState('');
  const [keyword, setkeyword] = useState('');
  const [url, setUrl] = useState('');
  const [titleTag, setTitleTag] = useState('');
  const [metaDescriptionTag, setMetaDescriptionTag] = useState('');
  const [actualHtmlContent, setActualHtmlContent] = useState('');
  const [analyseResult, setAnalyseResult] = useState('');
  const [list, setList] = useState({});

  const [keywords, setKeywords] = useState('');
  const [combinedKeywordsList, setCombinedKeywordsList] = useState('');
  const [combinedKeywords, setCombinedKeywords] = useState([]);
  const [language, setLanguage] = useState(ANALYSELANGAUGESLIST[0]);
  const [loading] = useState(false);

  const [isDisabled] = useState(false);
  const [websites, setWebsites] = useState('');
  const [stuffedKeywords, setStuffedKeywords] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const isEmpty = (str) => !str?.length;
  const isPremium =
    textForm &&
    !isEmpty(textForm.url) &&
    (!isEmpty(textForm.initialTextContent) ||
      !isEmpty(textForm.initialHtmlContent)) &&
    orderType === 'PREMIUM';
  const hasInitialTextContent =
    textForm &&
    (!isEmpty(textForm.initialTextContent) ||
      !isEmpty(textForm.initialHtmlContent));
  const colWidth = isPremium || hasInitialTextContent ? 6 : 12;
  useEffect(() => {
    document.title = 'Typix - Détails du texte';
    window.dispatchEvent(new Event('locationchange'));
  }, []);

  useEffect(() => {
    if (textForm) {
      setTextTitle(textForm.textTitle);
      setkeyword(textForm.keyword);
      setUrl(textForm.url);
      setTitleTag(textForm.titleTag);
      setMetaDescriptionTag(textForm.metaDescriptionTag);
      setWebsites(textForm.internalLinking);
      setStuffedKeywords(textForm.stuffedKeywords);
      // setActualTextContent(textForm.actualTextContent)
      setActualHtmlContent(
        textForm.actualHtmlContent
          ? textForm.actualHtmlContent
          : textForm.actualTextContent
      );

      let result = textForm.analyseResult && JSON.parse(textForm.analyseResult);
      setAnalyseResult(
        result && typeof result === 'string' ? JSON.parse(result) : result
      );
      setKeywords(
        textForm.semanticGap
          ? extractKeywordsWithFrequency(textForm.semanticGap)
          : ''
      );
      setCombinedKeywords(formatKeywordData(textForm.semanticGap));
      setCombinedKeywordsList(textForm.semanticGap);
      setList(
        textForm.semanticGap.reduce((acc, curr) => {
          acc[curr.word] = curr.frequency;
          return acc;
        }, {})
      );
    }
  }, [isLoading, textForm]);

  const handleInputChange = (e) => {
    setWebsites(e.target.value);
  };

  const handleDivClick = () => {
    setIsEditing(true);
  };

  if (isLoading || isError) return <Loader />;

  return (
    <div className="content-center">
      <div className="content-center">
        <div className="box-header-top header-info">
          <span
            className="bt-circle me-3"
            onClick={() => {
              dispatch(fetchClientOrderDisplay(orderId));
              dispatch(fetchClientTextOrderDisplay(orderId));
              history.goBack();
            }}
          ></span>
          <h1>{textTitle}</h1>
        </div>

        <div className="row">
          <div className="col-12 col-md-12">
            <div className="box-wrapper-gray">
              <div className="box-txt">
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => setExpanded(!expanded)}
                >
                  <strong>{t('Détails du texte')} :</strong>{' '}
                  {t('(Titre, mot clé, url, balise, méta-description)')}
                </div>
                <div
                  className="i-13"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setExpanded(!expanded)}
                >
                  <i className={`fa fa-chevron-${expanded ? 'up' : 'down'}`} />
                </div>
              </div>
              {expanded ? (
                <>
                  <div className="row row-mb">
                    <div className="col-12 col-md-6">
                      <div className="label-title">{t('Titre du texte')}</div>
                      <div>
                        <input
                          type="text"
                          className="form-control form-ct"
                          placeholder="Nom du texte"
                          value={textTitle}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="label-title">{t('Mot clé')}</div>
                      <div>
                        <input
                          type="text"
                          className="form-control form-ct"
                          placeholder="Mot clé"
                          value={keyword}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row row-mb">
                    <div className="col-12 col-md-6">
                      <div className="label-title">URL</div>
                      <div>
                        <input
                          type="text"
                          className="form-control form-ct"
                          placeholder="https://www."
                          value={url}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="label-title">{t('Balise titre')}</div>
                      <div>
                        <input
                          type="text"
                          className="form-control form-ct"
                          placeholder="Balise titre"
                          value={titleTag}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div className="label-title">
                        {t('Balise meta-description')}
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control form-ct"
                          placeholder="Balise meta-description"
                          value={metaDescriptionTag}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            {(textType === 'NON_TRANSLATED' || textType === 'COMBINED') && (
              <>
                <div className="mt-3">
                  <div className="label-title label-text-h">
                    {t('Mettez des mots-clés avec fréquence')} :
                    <CustomTooltip
                      title={
                        <div style={{ marginLeft: '10px' }}>
                          <div className="row">{t('Format souhaité')} :</div>
                          <div className="row">{t('mot-clé (fréquence)')}</div>
                          <div className="row">
                            {t('Séparez les mots-clés par un Enter .')}
                          </div>
                          <div className="row">
                            ---------------------------------
                          </div>
                          <div className="row">{t('Exemple')} :</div>
                          <div className="row">{t('mot-clé')}1 (2)</div>
                          <div className="row">{t('mot-clé')}2 (1)</div>
                          <div className="row">{t('mot-clé')}3 (1)</div>
                          <div className="row">{t('mot-clé')}4 (2)</div>
                        </div>
                      }
                      placement="top"
                    >
                      <button
                        type="button"
                        className="btn-circle"
                        style={{ marginLeft: 5 }}
                      ></button>
                    </CustomTooltip>
                  </div>
                </div>
                <div className="row">
                  <div
                    className={
                      textForm.delivered ? 'col-12 col-md-5' : 'col-12 col-md-2'
                    }
                  >
                    <div className="box-wrapper">
                      <textarea
                        className=" col-12  box-list-text"
                        placeholder={
                          'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n…'
                        }
                        value={combinedKeywords}
                        readOnly
                      ></textarea>
                    </div>
                  </div>
                  {!textForm.delivered && (
                    <div
                      className={'col-12 col-md-3 box-wrapper '}
                      onClick={handleDivClick}
                    >
                      {isEditing ? (
                        <textarea
                          disabled={true}
                          className=" col-12  box-list-text box-transparent"
                          placeholder="https//www.websites.com"
                          value={websites}
                          onChange={handleInputChange}
                          onBlur={() => setIsEditing(false)}
                          style={{ color: 'black' }}
                        />
                      ) : (
                        <div>
                          <div className="header-title mb-0">
                            <Fragment>{t('Maillage Interne')}</Fragment>
                          </div>
                          {websites &&
                            websites.split('\n').map((word, index) => (
                              <Fragment key={index}>
                                {(actualHtmlContent.includes(
                                  replaceAmpersands(word.trim()) + '/ '
                                ) ||
                                  actualHtmlContent.includes(
                                    replaceAmpersands(word.trim()) + ' '
                                  ) ||
                                  actualHtmlContent.includes(
                                    replaceAmpersands(word.trim()) + '?'
                                  ) ||
                                  actualHtmlContent.includes(
                                    replaceAmpersands(word.trim()) + '"'
                                  ) ||
                                  actualHtmlContent.includes(
                                    replaceAmpersands(word.trim()) + '>'
                                  ) ||
                                  actualHtmlContent.includes(
                                    replaceAmpersands(word.trim()) + '<'
                                  ) ||
                                  actualHtmlContent.includes(
                                    replaceAmpersands(word.trim()) + '/?'
                                  ) ||
                                  actualHtmlContent.includes(
                                    replaceAmpersands(word.trim()) + '/"'
                                  ) ||
                                  actualHtmlContent.includes(
                                    replaceAmpersands(word.trim()) + '/>'
                                  ) ||
                                  actualHtmlContent.includes(
                                    replaceAmpersands(word.trim()) + '/<'
                                  )) &&
                                !actualHtmlContent.includes(
                                  replaceAmpersands(word.trim()) + '&'
                                ) ? (
                                  <div>
                                    <mark
                                      style={{
                                        color: 'green',
                                        backgroundColor: '#2cce6e',
                                      }}
                                    >
                                      {word}
                                    </mark>
                                    <br></br>
                                  </div>
                                ) : (
                                  <div>
                                    <mark
                                      style={{
                                        color: 'red',
                                        backgroundColor: '#ffc2c2',
                                      }}
                                    >
                                      {word}
                                    </mark>
                                    <br></br>
                                  </div>
                                )}
                              </Fragment>
                            ))}
                        </div>
                      )}
                    </div>
                  )}

                  <div className="col-12 col-md-3">
                    <div
                      className="box-wrapper box-list-text"
                      style={{ height: '284px; !important' }}
                    >
                      <div className="header-title mb-0">
                        {t('Résultat sémantique')}
                      </div>
                      <div className="sub-header-title">
                        {t('Enrichissement sémantique')} :
                      </div>
                      <div className="box-graph">
                        {loading && <Loader />}
                        {analyseResult &&
                        analyseResult.semanticEnrichment >= 0 ? (
                          <div className="d-flex justify-content-center">
                            <CustomHighcharts
                              value={analyseResult.semanticEnrichment}
                            />
                          </div>
                        ) : null}
                      </div>
                      <div>
                        <button
                          className="bt-submit-y"
                          style={{ margin: '0 auto' }}
                          onClick={() =>
                            DownloadOneTextWordType(textForm.id, user)
                          }
                        >
                          {t('Télécharger le document')}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      textForm.delivered ? 'col-12 col-md-3' : 'col-12 col-md-2'
                    }
                  >
                    <div className="box-wrapper">
                      <div className="header-title mb-0">
                        {t('Mots clés manquants')} :
                      </div>
                      <br />
                      <div className="box-list-text box-motcle">
                        {loading && <Loader />}
                        <ul>
                          {analyseResult &&
                          analyseResult.greenWordsByFreq
                            .greenWordsWithRemainingFreq &&
                          Object.keys(
                            analyseResult.greenWordsByFreq
                              .greenWordsWithRemainingFreq
                          ).length > 0 ? (
                            <div>
                              <b>
                                {t(
                                  'Ajout de mots-clés avec la fréquence restante'
                                )}{' '}
                                :{' '}
                              </b>
                              <p>
                                {Object.keys(
                                  analyseResult.greenWordsByFreq
                                    .greenWordsWithRemainingFreq
                                ).map((item, key) => (
                                  <li key={key}>
                                    {' '}
                                    <span>
                                      {' '}
                                      {item} (
                                      <span
                                        style={{
                                          textDecorationLine: 'line-through',
                                        }}
                                      >
                                        {list[item]}
                                      </span>
                                      ,
                                      {
                                        analyseResult.greenWordsByFreq
                                          .greenWordsWithRemainingFreq[item]
                                      }
                                      ){' '}
                                    </span>{' '}
                                  </li>
                                ))}
                              </p>
                            </div>
                          ) : null}
                          {analyseResult &&
                          analyseResult.remainingKeywords &&
                          Object.keys(analyseResult.remainingKeywords).length >
                            0 ? (
                            <di>
                              <b>{t('Mots clés restants')}:</b>
                              <p>
                                {Object.keys(
                                  analyseResult.remainingKeywords
                                ).map((item, key) => (
                                  <li key={key}>
                                    {' '}
                                    <span>
                                      {' '}
                                      {item} (
                                      {analyseResult.remainingKeywords[item]}){' '}
                                    </span>{' '}
                                  </li>
                                ))}
                              </p>
                            </di>
                          ) : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {!textForm.delivered && (
                    <div className="col-12 col-md-2">
                      <div className="box-wrapper">
                        <div className="header-title mb-0">
                          {t('Mots clés Bourrées')} :
                        </div>
                        <br />
                        <div className="box-list-text box-motcle">
                          <ul>
                            {stuffedKeywords &&
                            Object.keys(stuffedKeywords).length > 0 ? (
                              <div>
                                <p>
                                  {stuffedKeywords.map((item) => {
                                    const tfPercentage =
                                      item['tfPercentage'] * 100;
                                    let colorClass;

                                    if (tfPercentage > 2) {
                                      colorClass = 'keywordStuffedRed';
                                    } else if (
                                      tfPercentage > 1.5 &&
                                      tfPercentage <= 2
                                    ) {
                                      colorClass = 'keywordStuffedOrange';
                                    } else {
                                      colorClass = 'keywordStuffedYellow';
                                    }

                                    return (
                                      <li
                                        key={item['word']}
                                        className={colorClass}
                                      >
                                        <span> {item['word']} </span> (
                                        <span>{tfPercentage.toFixed(2)}% </span>
                                        )
                                      </li>
                                    );
                                  })}
                                </p>
                              </div>
                            ) : (
                              <b className="keywordStuffedGreen">
                                {t('Aucun mots clés bourrées détécté')}
                              </b>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            {(textForm.globalRevisionSent || textForm.revisionSent) &&
              textForm.revisionText && (
                <RevisionDetails
                  t={t}
                  textId={textForm.id}
                  revisionRequestAt={textForm.revisionRequestAt}
                  revisionText={textForm.revisionText}
                  revisionFileName={textForm.revisionFileName}
                />
              )}
            {/* <div className="row mt-2">
              <div className="col-12 col-md-12">
                <div>
                  <div className="label-title label-text-h">
                    {t('Mettez votre texte')} :
                  </div>

                  <div className="box-wrapper-editor">
                    <CustomEditor
                      isReadOnly={isDisabled ? true : false}
                      content={actualHtmlContent}
                      onInit={(value, editor) => {
                        //   setActualTextWordCount(
                        //   editor.plugins.wordcount.getCount()
                        // )
                      }}
                      onChange={(value, editor) => {
                        setActualHtmlContent(value);
                        // let textValue = editor.getContent({ format: "text" })
                        // setActualTextContent(textValue)
                        // setActualTextWordCount(
                        //   editor.plugins.wordcount.getCount()
                        // )
                      }}
                      t={t}
                      orderType={orderType}
                    />
                  </div>
                </div>
              </div>
            </div> */}

            <div className="row mt-2">
              {hasInitialTextContent ? (
                <div className="col-12 col-md-6">
                  <div>
                    <div className="label-title label-text-h">
                      {t('Texte initial')}
                    </div>

                    <div className="box-wrapper-editor">
                      <CustomEditor
                        isReadOnly={true}
                        content={textForm.initialHtmlContent}
                        t={t}
                      />
                    </div>
                  </div>
                </div>
              ) : null}

              <div className={`col-12 col-md-${colWidth}`}>
                <div>
                  <div className="label-title label-text-h">
                    {t('Mettez votre texte')} :
                  </div>

                  {/* Text Component */}

                  <div className="box-wrapper-editor">
                    {!textForm && <Loader />}
                    <CustomEditor
                      isReadOnly={isDisabled ? true : false}
                      content={actualHtmlContent}
                      onInit={(value, editor) => {}}
                      onChange={(value, editor) => {
                        setActualHtmlContent(value);
                      }}
                      t={t}
                      orderType={orderType}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientTextDisplay;
