import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import he from 'he';

import { Form } from '@unform/web';

import {
  updateForm,
  updateTextForm,
  writerAnalyzerSelector,
} from 'store/Writer/Analyser';

import { UserSessionSelector } from 'store/UserSession';
import {
  updateTextOfTextList,
  writerOrderDisplaySelector,
} from 'store/Writer/OrderDisplay';
import {
  fetchWriterOrderDisplay,
  fetchWriterTextOrderDisplay,
} from 'store/Writer/OrderDisplay/features';
import { fetchAllOrders } from 'store/Writer/OrderList/features';

import { Loader } from 'components/shared/Loader';

import ComparisonModal from 'components/shared/modal/ComparisonModal';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import AutoSaveDisplay from 'components/shared/AutoSaveDisplay';

import { InputField } from 'components/shared/InputField';

import { CustomEditor } from 'components/shared/CustomEditor';
import { CustomHighcharts } from 'components/shared/CustomHighcharts';
import { CustomTooltip } from 'components/shared/CustomTooltip';

import { DownloadOneTextWordType } from 'components/shared/DownloadFile/DownloadTextWordType';
import { DisplayTextAmountColorAndCheckValidity } from 'components/shared/TextAmountColor';
import { RevisionDetails } from 'components/shared/RevisionDetails';

import AnalyseText from 'components/Admin/AnalyseText';

import axiosPrivate from 'config/axiosPrivate';

import KeywordStuffingModal from 'components/shared/modal/KeywordStuffingModals/KeywordStuffingModal';
import axiosStuffedDetector from 'config/axiosDetectStuffedKeywords';
import axiosMonetoring from 'config/axiosMonetoring';
import { capitalize } from 'helpers/Capitalize';
import {
  setKeyWordList,
  checkBrackets,
  isValidFormat,
} from 'helpers/PdfFunction';
import { checkData } from 'helpers/formHelper';
import {
  ANALYSELANGAUGESLIST,
  GOVERNED_WRITERS_WITH_VALIDATION,
  KeywordStuffingModalPrompts,
  SavingState,
} from 'utils/Constants';
import {
  replaceAmpersands,
  replaceTextInBold,
  specialCharsToEntities,
} from 'utils/StringUtils';
import {
  cleanWordListTotal,
  extractWords,
  filterWordsCommon75,
  filterkeywordsStuffing,
  getPotentialKeywordStuffingVarients,
  getTheAddedText,
  replaceTextInColor,
} from 'utils/keywordStuffing/keywordStuffing';
import { keywordsAutoGeneration } from 'utils/KeywordsAutoGeneration/keywordsAutoGeneration';

import { animationUrl } from 'config/endpoints';

import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';
import {
  formatKeywordData,
  extractKeywordsWithFrequency,
  transformData,
  transformToSemanticGap,
  extractStrKeywordsWithFrequency,
} from 'helpers/FormatKeywordsHelper';
import { autoSaveText } from 'helpers/CreateAutoSave';

function WriterCreateText() {
  const history = useHistory();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const { t } = useTranslation();

  const { user } = useSelector(UserSessionSelector);
  const {
    formData,
    orderId,
    orderType,
    orderSiloName,
    isLoading,
    isError,
    textType,
  } = useSelector(writerAnalyzerSelector);
  const { minWords, maxWords, order } = useSelector(writerOrderDisplaySelector);
  const { limit, analysis } = useSelector(UserSessionSelector);

  const RED = '#EE3900';
  const ORANGE = '#FF8D4B';
  const BLACK = '#3c3c3c';

  const [textTitle, setTextTitle] = useState('');
  const [keyword, setkeyword] = useState('');

  const [url, setUrl] = useState('');
  const [titleTag, setTitleTag] = useState('');
  const [metaDescriptionTag, setMetaDescriptionTag] = useState('');

  const [initialTextContent, setInitialTextContent] = useState('');
  const [initialHtmlContent, setInitialHtmlContent] = useState('');

  const [actualTextContent, setActualTextContent] = useState('');
  const [actualHtmlContent, setActualHtmlContent] = useState('');

  // const [activeInitialText, setActiveInitialText] = useState(false)

  const [initialTextWordCount, setInitialTextWordCount] = useState(0);

  const [validTextNbr, setValidTextNbr] = useState(false);

  const [analyseResult, setAnalyseResult] = useState('');
  const [analyseText, setAnalyseText] = useState('');

  const [keywords, setKeywords] = useState('');
  const [combinedKeywordsList, setCombinedKeywordsList] = useState([]);
  const [combinedKeywords, setCombinedKeywords] = useState('');
  const [language, setLanguage] = useState(ANALYSELANGAUGESLIST[0]);
  const [analyseCount, setAnalyseCount] = useState(null);
  const [list, setList] = useState({});

  const [loading, setLoading] = useState(false);
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [disabledForm, setDisabledForm] = useState(true);
  const [isTextFieldDisabled, setIsTextFieldDisabled] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [isSendRevisionDisabled, setIsSendRevisionDisabled] = useState(false);

  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');

  const [showKeywordStuffingModal, setShowKeywordStuffingModal] =
    useState(false);

  const [keywordStuffingModalTitle, setKeywordStuffingModalTitle] =
    useState('');
  const [keywordStuffingModalMsg, setKeywordStuffingModalMsg] = useState('');
  const [keywordStuffingModalData, setKeywordStuffingModalData] = useState([]);
  const [keywordStuffingModalCloseBtn, setKeywordStuffingModalCloseBtn] =
    useState(true);
  const [showComparison, setShowComparison] = useState(false);
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [errorSuccessImg, setErrorSuccessImg] = useState(undefined);
  const [isSuccess, setIsSuccess] = useState(false);

  const [color, setColor] = useState(BLACK);
  const [isTextDelivered, setIsTextDelivered] = useState(false);
  const [showCreditButton, setShowCreditButton] = useState(false);
  const [orderTextsList, setOrderTextsList] = useState([]);
  const [websites, setWebsites] = useState('');
  const [allWebsitesArePresent, setAllWebsitesArePresent] = useState(false);
  const [stuffedKeywords, setStuffedKeywords] = useState([]);
  const [initialStuffedKeywords, setInitialStuffedKeywords] = useState([]);
  const [keywordStuffingLoading, setKeywordStuffingLoading] = useState(false);
  const [autoKeywordsGenerationLoading, setAutokeywordGenerationLoading] =
    useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [saving, setSaving] = useState(SavingState.NOT_SAVED);
  const [timer, setTimer] = useState(null);

  const [editorInitialized, setEditorInitialized] = useState(false);
  const editorRef = useRef(null);
  const actualTextWordCount = editorRef.current
    ? editorRef.current.plugins.wordcount.getCount()
    : 0;

  const isEmpty = (str) =>
    !Boolean(str?.length && str !== '\n' && str !== '\r');
  const isPremium =
    formData &&
    !isEmpty(formData.url) &&
    !isEmpty(formData.initialTextContent) &&
    orderType === 'PREMIUM';

  const isNotTranslation =
    (formData &&
      orderType === 'PREMIUM' &&
      !isEmpty(formData.url) &&
      !isEmpty(formData.initialTextContent) &&
      textType === 'NON_TRANSLATED') ||
    (!formData && orderType === 'PREMIUM' && textType === 'NON_TRANSLATED');

  const isDelivered =
    formData &&
    formData.globalRevisionSent === false &&
    formData.revisionSent === false &&
    formData.revisionDone === false &&
    formData.validated === false &&
    formData.delivered === true;

  const isRevisionDone =
    formData &&
    ((formData.globalRevisionSent === true && formData.revisionDone === true) ||
      (formData.revisionSent === true &&
        formData.revisionDone === true &&
        formData.validated === false));

  const isRevisionSent =
    formData &&
    ((formData.globalRevisionSent === true &&
      formData.revisionDone === false) ||
      (formData.revisionSent === true &&
        formData.revisionDone === false &&
        formData.validated === false));

  const isDraft =
    formData &&
    formData.globalRevisionSent === false &&
    formData.revisionSent === false &&
    formData.revisionDone === false &&
    formData.validated === false &&
    formData.delivered === false;

  const hasInitialTextContent =
    formData &&
    (!isEmpty(formData.initialTextContent) ||
      !isEmpty(formData.initialHtmlContent));
  const colWidth = !formData || isPremium || hasInitialTextContent ? 6 : 12;
  const stepNumber = !formData || isPremium || hasInitialTextContent ? 3 : 2;

  useEffect(() => {
    document.title = t('Typix - Détails du texte');
    window.dispatchEvent(new Event('locationchange'));
  }, [t]);

  useEffect(() => {
    if (!formData) return;
    setTextTitle(formData.textTitle);
    setkeyword(formData.keyword);
    setUrl(formData.url);
    setTitleTag(formData.titleTag);
    setMetaDescriptionTag(formData.metaDescriptionTag);
    setStuffedKeywords(formData.stuffedKeywords);
    setWebsites(formData.internalLinking);
    // setActiveInitialText(formData.activeInitialText)
    setActualTextContent(formData.actualTextContent);
    setActualHtmlContent(
      orderType !== 'PREMIUM' && !formData.actualHtmlContent
        ? formData.actualTextContent
        : formData.actualHtmlContent
    );

    setInitialTextContent(formData.initialTextContent);
    setInitialHtmlContent(formData.initialHtmlContent);

    setAnalyseText(
      formData.actualTextContent
        ? formData.actualTextContent
        : formData.actualHtmlContent
    );

    let result = formData.analyseResult && JSON.parse(formData.analyseResult);
    setAnalyseResult(
      result && typeof result === 'string' ? JSON.parse(result) : result
    );
    setKeywords(
      formData.semanticGap
        ? extractKeywordsWithFrequency(formData.semanticGap)
        : ''
    );
    setCombinedKeywords(
      formData.semanticGap ? formatKeywordData(formData.semanticGap) : ''
    );
    setCombinedKeywordsList(formData.semanticGap);
    setList(
      formData.semanticGap
        ? formData.semanticGap.reduce((acc, curr) => {
            acc[curr.word] = curr.frequency;
            return acc;
          }, {})
        : {}
    );
    checkIfAllLinesGreen(formData.actualHtmlContent, formData.internalLinking);
  }, [formData, isLoading]);

  useEffect(() => {
    setAnalyseCount(limit - analysis);
    if (!analyseCount) return;
    if (analyseCount >= 0 && analyseCount <= 5) {
      setColor(RED);

      setShowCreditButton(true);

      setErrorSuccessMsg(
        t(
          "Information dernière minute ! Ton stock de crédits sera bientôt épuisé :( Pour anticiper, n'hésite pas à demander dès maintenant plus de crédits pour terminer ta commande."
        )
      );
      setIsSuccess(false);
      setErrorSuccess(true);
      return;
    }

    if (analyseCount >= 6 && analyseCount <= 10) {
      setColor(ORANGE);
      return;
    }
  }, [limit, analysis, analyseCount, t]);

  function handleChange(e) {
    setCombinedKeywords(e.target.value);
    setKeywords(extractStrKeywordsWithFrequency(e.target.value));
  }

  const checkValidForm = useCallback(() => {
    if (
      (!formData || (formData && !formData.delivered)) &&
      textTitle.length > 0 &&
      keyword.length > 0 &&
      titleTag.length > 0 &&
      metaDescriptionTag.length > 0 &&
      validTextNbr &&
      analyseResult &&
      (orderType !== 'SMART_WITH_REVIEW'
        ? analyseResult.semanticEnrichment >= 85
        : true) &&
      textType === 'NON_TRANSLATED'
    ) {
      setDisabledForm(false);
      return;
    }

    if (
      (!formData || (formData && !formData.delivered)) &&
      textTitle.length > 0 &&
      keyword.length > 0 &&
      titleTag.length > 0 &&
      metaDescriptionTag.length > 0 &&
      initialTextWordCount &&
      !isEmpty(actualTextContent) &&
      actualTextWordCount >= minWords &&
      textType === 'TRANSLATED'
    ) {
      setDisabledForm(false);
      return;
    }

    if (
      (!formData || (formData && !formData.delivered)) &&
      textTitle.length > 0 &&
      keyword.length > 0 &&
      titleTag.length > 0 &&
      metaDescriptionTag.length > 0 &&
      // validTextNbr &&
      analyseResult &&
      (orderType !== 'SMART_WITH_REVIEW'
        ? analyseResult.semanticEnrichment >= 85
        : true) &&
      actualTextWordCount >= minWords &&
      textType === 'COMBINED'
    ) {
      setDisabledForm(false);
      return;
    }

    setDisabledForm(true);
    return;
  }, [
    actualTextContent,
    actualTextWordCount,
    analyseResult,
    formData,
    initialTextWordCount,
    keyword.length,
    metaDescriptionTag.length,
    minWords,
    textTitle.length,
    textType,
    titleTag.length,
    validTextNbr,
  ]);

  useEffect(
    () => checkValidForm(),
    [
      checkValidForm,
      formData,
      textTitle,
      keyword,
      keywords,
      titleTag,
      url,
      metaDescriptionTag,
      actualTextContent,
      analyseResult,
      validTextNbr,
    ]
  );

  const enableSave = useCallback(() => {
    if (
      (formData && formData.url && !formData.activeInitialText) ||
      isEmpty(initialTextContent) ||
      isEmpty(url)
    ) {
      setIsSaveDisabled(true);
      return;
    }
    setIsSaveDisabled(false);
    return;
  }, [formData, initialTextContent, url]);

  useEffect(
    () => enableSave(),
    [formData, url, initialTextContent, enableSave]
  );

  const enableSendRevision = useCallback(() => {
    if (formData && formData.revisionDone) {
      setIsSendRevisionDisabled(true);
      return;
    }
    setIsSendRevisionDisabled(false);
    return;
  }, [formData]);

  useEffect(() => enableSendRevision(), [enableSendRevision, formData]);

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, [timer]);

  // Form submission handler

  async function validateData(data, { reset }, event) {
    let action = event.nativeEvent.submitter.value;

    await checkData(
      data,
      formRef,
      action,
      PutToDraft,
      DeliverText,
      ValidateText,
      KeywordStuffingCall,
      setConfirmationMsg,
      setConfirmation
    );
  }

  const handleSave = async () => {
    const isKeywordsValid =
      textType !== 'TRANSLATED' ? await isValidFormat(combinedKeywords) : {};

    const requestData = {
      activeInitialText: false,
      actualHtmlContent: initialHtmlContent,
      actualTextContent: initialTextContent,
      analyseResult: analyseResult && JSON.stringify(formData?.analyseResult),
      initialHtmlContent: initialHtmlContent,
      initialTextContent: initialTextContent,
      keyword: keyword,
      metaDescriptionTag: metaDescriptionTag,
      semanticGap: isKeywordsValid
        ? transformToSemanticGap(combinedKeywords)
        : null,
      textTitle: textTitle,
      titleTag: titleTag,
      totalNumberOfTextAdded:
        actualTextContent && textType === 'NON_TRANSLATED'
          ? actualTextWordCount - initialTextWordCount
          : actualTextWordCount,

      url: url,
      type: textType,
      stuffedKeywords: stuffedKeywords,
      internalLinking: websites,
      initialStuffedKeywords: initialStuffedKeywords,
    };

    const handleSuccess = (res) => {
      dispatch(updateForm(res.data));
      setIsTextFieldDisabled(true);
      setConfirmation(false);
      setActualTextContent(initialTextContent);
      setActualHtmlContent(initialHtmlContent);
      setErrorSuccessMsg(() => {
        if (textType === 'TRANSLATED') {
          return `${t('La traduction a été enregistrée avec succès')}!`;
        } else if (textType === 'COMBINED') {
          return `${t('Texte + Traduction enregistré avec succès')}!`;
        } else {
          return `${t('Le texte a été enregistré avec succès')}!`;
        }
      });
      setErrorSuccessImg(animationUrl + '/05_Contenu_livré.gif');
      setIsSuccess(true);
      setErrorSuccess(true);
    };

    const handleError = (e) => {
      switch (e.response.status) {
        case 400:
          setErrorSuccessMsg(
            `${t(
              'Veuillez changer les informations suivantes titre et/ou mot clé'
            )}`
          );
          break;
        default:
          setErrorSuccessMsg(`Oups...! ${t("Une erreur s'est produite")}. `);
          break;
      }
      setIsSuccess(false);
      setErrorSuccess(true);
    };

    if (formData) {
      axiosPrivate
        .put(`/texts/${formData.id}`, requestData)
        .then((res) => handleSuccess(res))
        .catch((e) => handleError(e));
    } else {
      const postData = {
        ...requestData,
        commandId: orderId,
      };

      axiosPrivate
        .post('/texts', postData)
        .then((res) => handleSuccess(res))
        .catch(() => {
          setErrorSuccessMsg(`Oups...! ${t("Une erreur s'est produite")}. `);
          setIsSuccess(false);
          setErrorSuccess(true);
        });
    }
  };

  const PutToDraft = async (demandeRevision = '') => {
    const isKeywordsValid =
      textType !== 'TRANSLATED' ? await isValidFormat(combinedKeywords) : {};

    const semanticGap = transformToSemanticGap(combinedKeywords);

    if (!isKeywordsValid && textType !== 'TRANSLATED') {
      setErrorSuccessMsg(
        ` ${t(
          'Veuillez corriger le format de votre gap sémantique afin de pouvoir sauvegarder le texte en brouillon'
        )}. `
      );
      setIsSuccess(false);
      setErrorSuccess(true);
      return;
    }

    if (orderType === 'PREMIUM') {
      if (!isEmpty(url) && isEmpty(initialTextContent)) {
        setErrorSuccessMsg(
          ` ${t(
            'Veuillez ajouter un texte initiale afin de pouvoir sauvegarder le texte en brouillon'
          )}. `
        );
        setIsSuccess(false);
        setErrorSuccess(true);
        return;
      } else if (isEmpty(url) && !isEmpty(initialTextContent)) {
        setErrorSuccessMsg(
          ` ${t(
            'Veuillez ajouter une url afin de pouvoir sauvegarder le texte en brouillon'
          )}. `
        );
        setIsSuccess(false);
        setErrorSuccess(true);
        return;
      }
    }

    const requestData = {
      activeInitialText: false,
      actualHtmlContent: actualHtmlContent,
      actualTextContent: actualTextContent,
      analyseResult: analyseResult && JSON.stringify(analyseResult),
      initialHtmlContent: initialHtmlContent,
      initialTextContent: initialTextContent,
      keyword: keyword,
      metaDescriptionTag: metaDescriptionTag,
      semanticGap: semanticGap,
      textTitle: textTitle,
      titleTag: titleTag,
      totalNumberOfTextAdded:
        actualTextContent && textType === 'NON_TRANSLATED'
          ? actualTextWordCount - initialTextWordCount
          : actualTextWordCount,

      url: url,
      type: textType,
      stuffedKeywords: stuffedKeywords,
      internalLinking: websites,
      initialStuffedKeywords: initialStuffedKeywords,
    };

    const handleSuccess = (res) => {
      setIsFetchLoading(false);
      dispatch(updateForm(res.data));
      if (demandeRevision === 'keywordStuffingDraft') {
        setErrorSuccessMsg(
          t(KeywordStuffingModalPrompts.successDemandeRevisionKeywordStuffing)
        );
        axiosStuffedDetector
          .post(`/texts/keywordStuffing/notifyAdmin`, res.data['id'], {
            headers: {
              accept: 'application/json',
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            setErrorSuccessMsg(
              t(
                KeywordStuffingModalPrompts.successDemandeRevisionKeywordStuffing
              )
            );
            setShowKeywordStuffingModal(false);
            setIsSuccess(true);
            setErrorSuccess(true);
          })
          .catch((err) => {
            setErrorSuccessMsg(
              t(KeywordStuffingModalPrompts.errorDemandeRevisionKeywordStuffing)
            );
            setShowKeywordStuffingModal(false);
            setIsSuccess(false);
            setErrorSuccess(true);
          });
      }
      if (demandeRevision === '') {
        setErrorSuccessMsg(
          `${t('Le texte')} ${formData?.textTitle || textTitle}  ${t(
            'a été mis en brouillon'
          )}!`
        );
        setErrorSuccessImg(animationUrl + '/04_Contenu_validé.gif');
      }
      setIsSuccess(true);
      setErrorSuccess(true);
    };

    const handleError = (e) => {
      setIsFetchLoading(false);

      switch (e.response.status) {
        case 400:
          setErrorSuccessMsg(
            `${t(
              'Veuillez changer les informations suivantes titre et/ou mot clé'
            )}`
          );
          break;
        default:
          setErrorSuccessMsg(`Oups...! ${t("Une erreur s'est produite")}. `);
          break;
      }
      setIsSuccess(false);
      setErrorSuccess(true);
    };

    setIsFetchLoading(true);

    if (formData) {
      axiosPrivate
        .put(`/texts/${formData.id}`, requestData)
        .then((res) => {
          handleSuccess(res);
        })
        .catch((e) => {
          handleError(e);
        });
    } else {
      const postRequestData = {
        ...requestData,
        commandId: orderId,
      };

      axiosPrivate
        .post('/texts', postRequestData)
        .then((res) => {
          handleSuccess(res);
        })
        .catch((e) => {
          handleError(e);
        });
    }
  };

  const ValidateText = async (reinitilazedActualHtmlContent = '') => {
    console.log(formData);
    const isKeywordsValid =
      textType !== 'TRANSLATED' ? await isValidFormat(combinedKeywords) : {};

    if (!isKeywordsValid) {
      setErrorSuccessMsg(
        ` ${t(
          'Veuillez corriger le format de votre gap sémantique afin de pouvoir sauvegarder le texte en brouillon'
        )}. `
      );
      setIsSuccess(false);
      setErrorSuccess(true);
      return;
    }

    const titleLength = titleTag ? titleTag.length : 0;
    const metaDescriptionLength = metaDescriptionTag
      ? metaDescriptionTag.length
      : 0;

    if (titleLength <= 5 || metaDescriptionLength <= 5) {
      setErrorSuccessMsg(
        ` ${t(
          'La taille du titre et/ou la méta-description est trop courte'
        )}. `
      );
      setIsSuccess(false);
      setErrorSuccess(true);
      return;
    }
    setIsFetchLoading(true);

    let data = {
      activeInitialText: false,
      actualHtmlContent:
        reinitilazedActualHtmlContent.length > 0
          ? reinitilazedActualHtmlContent
          : actualHtmlContent,
      actualTextContent: actualTextContent,
      analyseResult: analyseResult && JSON.stringify(analyseResult),
      initialHtmlContent: initialHtmlContent,
      initialTextContent: initialTextContent,
      keyword: keyword,
      metaDescriptionTag: metaDescriptionTag,
      semanticGap: transformToSemanticGap(combinedKeywords),
      textTitle: textTitle,
      titleTag: titleTag,
      totalNumberOfTextAdded:
        actualTextContent && textType === 'NON_TRANSLATED'
          ? actualTextWordCount - initialTextWordCount
          : actualTextWordCount,

      url: url,
      type: textType,
      stuffedKeywords: [],
      internalLinking: websites,
      initialStuffedKeywords: [],
    };

    const handleSuccess = (res) => {
      setIsFetchLoading(false);
      setIsTextDelivered(true);
      setOrderTextsList(res.data);

      setErrorSuccessMsg(
        `${t('Le texte')} ${textTitle} ${t(
          'Le texte a été validé avec succès'
        )}!`
      );
      setErrorSuccessImg(animationUrl + '/04_Contenu_validé.gif');
      setIsSuccess(true);
      setErrorSuccess(true);
    };

    const handleError = (e) => {
      setIsFetchLoading(false);

      switch (e.response.status) {
        case 400:
          setErrorSuccessMsg(
            `${t(
              'Veuillez changer les informations suivantes titre et/ou mot clé'
            )}`
          );
          break;
        default:
          setErrorSuccessMsg(`Oups...! ${t("Une erreur s'est produite")}.`);
          break;
      }
      setIsSuccess(false);
      setErrorSuccess(true);
    };

    const validerText = (method, url, requestData) => {
      axiosPrivate({
        method,
        url,
        data: requestData,
      })
        .then((response) => {
          if (response.status === 200) {
            dispatch(updateForm(response.data));
            dispatch(fetchWriterOrderDisplay(orderId));

            dispatch(
              updateTextOfTextList({
                id: response.data.id,
                key: 'delivered',
                value: true,
              })
            );
            dispatch(
              updateTextOfTextList({
                id: response.data.id,
                key: 'keyword',
                value: keyword,
              })
            );
          } else {
            throw new Error('Failed to update text information.');
          }
        })
        .then(() => {
          return new Promise((resolve) => setTimeout(resolve, 2000));
        })
        .then(() => {
          return axiosPrivate.get(
            '/commands/' + orderId + '/texts/narrow-details'
          );
        })
        .then((response) => handleSuccess(response))
        .catch((e) => handleError(e));
    };

    const validerUrl = `/texts/${formData.id}/validate`;

    const validerData = { ...data };

    validerText('PUT', validerUrl, validerData);
  };

  const DeliverText = async (reinitilazedActualHtmlContent = '') => {
    const isKeywordsValid =
      textType !== 'TRANSLATED' ? await isValidFormat(combinedKeywords) : {};

    if (!isKeywordsValid) {
      setErrorSuccessMsg(
        ` ${t(
          'Veuillez corriger le format de votre gap sémantique afin de pouvoir sauvegarder le texte en brouillon'
        )}. `
      );
      setIsSuccess(false);
      setErrorSuccess(true);
      return;
    }

    const titleLength = titleTag ? titleTag.length : 0;
    const metaDescriptionLength = metaDescriptionTag
      ? metaDescriptionTag.length
      : 0;

    if (titleLength <= 5 || metaDescriptionLength <= 5) {
      setErrorSuccessMsg(
        ` ${t(
          'La taille du titre et/ou la méta-description est trop courte'
        )}. `
      );
      setIsSuccess(false);
      setErrorSuccess(true);
      return;
    }
    setIsFetchLoading(true);

    let data = {
      activeInitialText: false,
      actualHtmlContent:
        reinitilazedActualHtmlContent.length > 0
          ? reinitilazedActualHtmlContent
          : actualHtmlContent,
      actualTextContent: actualTextContent,
      analyseResult: analyseResult && JSON.stringify(analyseResult),
      initialHtmlContent: initialHtmlContent,
      initialTextContent: initialTextContent,
      keyword: keyword,
      metaDescriptionTag: metaDescriptionTag,
      semanticGap: transformToSemanticGap(combinedKeywords),
      textTitle: textTitle,
      titleTag: titleTag,
      totalNumberOfTextAdded:
        actualTextContent && textType === 'NON_TRANSLATED'
          ? actualTextWordCount - initialTextWordCount
          : actualTextWordCount,

      url: url,
      type: textType,
      stuffedKeywords: [],
      internalLinking: websites,
      initialStuffedKeywords: [],
    };

    const handleSuccess = (res) => {
      setIsFetchLoading(false);
      setIsTextDelivered(true);
      setOrderTextsList(res.data);

      setErrorSuccessMsg(
        `${t('Le texte')} ${textTitle} ${t('a été livré avec succès')}!`
      );
      setErrorSuccessImg(animationUrl + '/05_Contenu_livré.gif');
      setIsSuccess(true);
      setErrorSuccess(true);
    };

    const handleError = (e) => {
      setIsFetchLoading(false);

      switch (e.response.status) {
        case 400:
          setErrorSuccessMsg(
            `${t(
              'Veuillez changer les informations suivantes titre et/ou mot clé'
            )}`
          );
          break;
        default:
          setErrorSuccessMsg(`Oups...! ${t("Une erreur s'est produite")}.`);
          break;
      }
      setIsSuccess(false);
      setErrorSuccess(true);
    };

    const deliverText = (method, url, requestData) => {
      axiosPrivate({
        method,
        url,
        data: requestData,
      })
        .then((response) => {
          if (response.status === 200) {
            dispatch(updateForm(response.data));
            dispatch(fetchWriterOrderDisplay(orderId));

            dispatch(
              updateTextOfTextList({
                id: response.data.id,
                key: 'delivered',
                value: true,
              })
            );
            dispatch(
              updateTextOfTextList({
                id: response.data.id,
                key: 'keyword',
                value: keyword,
              })
            );
          } else {
            throw new Error('Failed to update text information.');
          }
        })
        .then(() => {
          return new Promise((resolve) => setTimeout(resolve, 2000));
        })
        .then(() => {
          return axiosPrivate.get(
            '/commands/' + orderId + '/texts/narrow-details'
          );
        })
        .then((response) => handleSuccess(response))
        .catch((e) => handleError(e));
    };

    const deliverUrl =
      formData === null ? '/texts/deliver' : `/texts/${formData.id}/deliver`;
    const deliverData =
      formData === null ? { ...data, commandId: orderId } : { ...data };

    deliverText(formData === null ? 'POST' : 'PUT', deliverUrl, deliverData);
  };

  function sendRevision() {
    const list =
      textType !== 'TRANSLATED' ? isValidFormat(combinedKeywords) : {};
    setIsFetchLoading(true);

    axiosPrivate
      .put(`/texts/${formData.id}/revisionDone`, {
        actualHtmlContent: actualHtmlContent,
        actualTextContent: actualTextContent,
        analyseResult: analyseResult && JSON.stringify(analyseResult),
        keyword: keyword,
        metaDescriptionTag: metaDescriptionTag,
        semanticGap: transformToSemanticGap(combinedKeywords),
      })
      .then((res) => {
        setIsFetchLoading(false);
        dispatch(updateForm(res.data));
        dispatch(updateTextForm({ key: 'revisionDone', value: true }));
        dispatch(
          updateTextOfTextList({
            id: formData.id,
            key: 'revisionDone',
            value: true,
          })
        );

        setErrorSuccessMsg(`${t('Modification effectuée avec succès')}`);
        setErrorSuccessImg(animationUrl + '/09_Repasse_OK.gif');
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsFetchLoading(false);

        setErrorSuccessMsg(`Oups...! ${t("Une erreur s'est produite")}.`);
        setIsSuccess(false);
        setErrorSuccess(true);
      });
  }

  function resetFields() {
    setTextTitle('');
    setkeyword('');
    setUrl('');
    setTitleTag('');
    setMetaDescriptionTag('');
    // setActiveInitialText("")
    setActualTextContent('');
    setActualHtmlContent('');
    setInitialTextContent('');
    setInitialHtmlContent('');
    setAnalyseResult('');
    setKeywords('');
    setCombinedKeywords('');
    setWebsites('');
    setInitialStuffedKeywords([]);
    setStuffedKeywords([]);
    setList({});
    setIsTextDelivered(false);
  }

  function requestIncreaseAnalysis() {
    setErrorSuccess(false);

    axiosPrivate
      .put('/user/requestIncreaseAnalysis')
      .then((res) => {
        setShowCreditButton(false);
        setErrorSuccessMsg(`${t("La demande d'ajout de crédit est envoyée")}!`);
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch((e) => {
        setErrorSuccessMsg(`Oups...! ${t("Une erreur s'est produite")}.`);
        setIsSuccess(false);
        setErrorSuccess(true);
      });
  }

  const handleInputChange = (e) => {
    setWebsites(e.target.value);
    checkIfAllLinesGreen(actualHtmlContent, e.target.value);
  };

  const handleDivClick = () => {
    setIsEditing(true);
  };

  const KeywordStuffingCall = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setKeywordStuffingLoading(true);

    let reinitilazedActualHtmlContent = actualHtmlContent
      ? actualHtmlContent
      : actualTextContent;

    reinitilazedActualHtmlContent = reinitilazedActualHtmlContent.replace(
      new RegExp('style="color: red;"', 'g'),
      'style="color: black;"'
    );

    setActualHtmlContent(reinitilazedActualHtmlContent);
    const allKeywords = textTitle + ' ' + keywords;
    const wordsArray = extractWords(allKeywords);

    const actualTextContentValue =
      textType === 'NON_TRANSLATED' && initialTextContent
        ? getTheAddedText(initialTextContent, actualTextContent)
        : actualTextContent;

    const initialTextContentValue =
      (textType === 'NON_TRANSLATED') & initialTextContent
        ? initialTextContent
        : '';
    const possibleStuffedKeywords = [
      ...wordsArray,
      ...cleanWordListTotal(keywords),
    ];
    const initialTextWordCountValue =
      textType === 'NON_TRANSLATED' ? initialTextWordCount : 0;

    const postData = {
      actualTextContent: actualTextContentValue,
      initialTextContent: initialTextContentValue,
      possibleStuffedKeywords,
      initialTextWordCount: initialTextWordCountValue,
      actualTextWordCount,
    };

    axiosStuffedDetector
      .post(`/texts/keywordStuffing`, postData, {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const actualStuffedKeywordsList = filterWordsCommon75(
          filterkeywordsStuffing(response.data['actualStuffedKeywords'])
        );
        const initialStuffedKeywordsList = filterWordsCommon75(
          filterkeywordsStuffing(response.data['initialStuffedKeywords'])
        );
        setStuffedKeywords(actualStuffedKeywordsList);
        setInitialStuffedKeywords(initialStuffedKeywordsList);
        if (
          actualStuffedKeywordsList.length === 0 &&
          initialStuffedKeywordsList.length === 0
        ) {
          DeliverText(reinitilazedActualHtmlContent);
        }
        if (
          actualStuffedKeywordsList.length === 0 &&
          initialStuffedKeywordsList.length > 0
        ) {
          setKeywordStuffingModalTitle(
            t(KeywordStuffingModalPrompts.initialTextKeywordStuffingTitle)
          );
          setKeywordStuffingModalMsg(
            t(KeywordStuffingModalPrompts.initialTextKeywordStuffingMSg)
          );
          setKeywordStuffingModalData(initialStuffedKeywordsList);
          setKeywordStuffingModalCloseBtn(false);
          setShowKeywordStuffingModal(true);
        }
        if (actualStuffedKeywordsList.length > 0) {
          setKeywordStuffingModalTitle(
            t(KeywordStuffingModalPrompts.actualTextKeywordStuffingTitle)
          );
          setKeywordStuffingModalMsg(
            t(KeywordStuffingModalPrompts.actualTextKeywordStuffingMsg)
          );
          setKeywordStuffingModalData(actualStuffedKeywordsList);
          setKeywordStuffingModalCloseBtn(true);
          setShowKeywordStuffingModal(true);
        }
        setKeywordStuffingLoading(false);
        if (actualStuffedKeywordsList.length > 0) {
          let stuffedActualHtmtlContent = reinitilazedActualHtmlContent;

          const newActualStuffedKeywordsList = actualStuffedKeywordsList.map(
            (actualStuffedKeyword) => actualStuffedKeyword['word']
          );

          if (orderType === 'PREMIUM') {
            setActualHtmlContent(
              replaceTextInColor(
                stuffedActualHtmtlContent,
                getPotentialKeywordStuffingVarients([
                  ...newActualStuffedKeywordsList,
                  ...response.data['actualTextMatchedResult'],
                ]),
                'red'
              )
            );
          } else {
            setActualTextContent(
              replaceTextInColor(
                stuffedActualHtmtlContent,
                getPotentialKeywordStuffingVarients([
                  ...newActualStuffedKeywordsList,
                  ...response.data['actualTextMatchedResult'],
                ]),
                'red'
              )
            );
          }
        }
        if (initialStuffedKeywordsList.length > 0) {
          const newInitialStuffedKeywordsList = initialStuffedKeywordsList.map(
            (initialStuffedKeyword) => initialStuffedKeyword['word']
          );
          setInitialHtmlContent(
            replaceTextInColor(
              getPotentialKeywordStuffingVarients([
                ...newInitialStuffedKeywordsList,
                ...response.data['initialTextMatchedResult'],
              ]),
              'purple'
            )
          );
        }
      })
      .catch((error) => {
        // Handle errors here
        setKeywordStuffingLoading(false);
        console.error(error);
      });
  };

  const revisionKeywordStuffing = () => {
    let demandeRevisionKeywordStuffing = 'keywordStuffingDraft';
    PutToDraft(demandeRevisionKeywordStuffing);
  };

  const checkIfAllLinesGreen = (actualHtmlContent, websites) => {
    const allGreen =
      !websites || websites?.length === 0
        ? true
        : websites.split('\n').every((word) => {
            const formattedWord = replaceAmpersands(word.trim());
            const formattedWordWithPunctuation = [
              formattedWord + '/ ',
              formattedWord + ' ',
              formattedWord + '?',
              formattedWord + '"',
              formattedWord + '>',
              formattedWord + '<',
              formattedWord + '/?',
              formattedWord + '/"',
              formattedWord + '/>',
              formattedWord + '/<',
              formattedWord + '&',
            ];
            return formattedWordWithPunctuation.some((formattedWordVariant) =>
              actualHtmlContent.includes(formattedWordVariant)
            );
          });
    setAllWebsitesArePresent(allGreen);
  };

  const handleEditorInit = (value, editor) => {
    editorRef.current = editor;
    setEditorInitialized(true);
  };

  if (isFetchLoading || isLoading || isError) return <Loader />;

  return (
    <div className="content-center">
      <Form ref={formRef} onSubmit={validateData}>
        <div className="content-center">
          <div className="box-header-top header-info">
            <span
              className="bt-circle me-3"
              onClick={() => {
                dispatch(fetchWriterOrderDisplay(orderId));
                dispatch(fetchWriterTextOrderDisplay(orderId));
                dispatch(fetchAllOrders());
                history.goBack();
              }}
            ></span>
            {formData ? (
              <h1> {formData.textTitle} </h1>
            ) : (
              <h1>{t('Nouveau texte')}</h1>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="box-wrapper-gray">
              <div className="box-txt">
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => setExpanded(!expanded)}
                >
                  <strong>{t('Détails du texte')} :</strong>{' '}
                  {t('(Titre, mot clé, url, balise, méta-description)')}
                </div>
                <div
                  className="i-13"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setExpanded(!expanded)}
                >
                  <i className={`fa fa-chevron-${expanded ? 'up' : 'down'}`} />
                </div>
              </div>
              {expanded ? (
                <>
                  <div className="row row-mb">
                    <div className="col-12 col-md-6">
                      <div className="label-title">{t('Nom du texte')}*</div>
                      <div>
                        <InputField
                          name="textTitle"
                          type="text"
                          className="form-control form-ct"
                          placeholder={t('Nom du texte')}
                          value={textTitle}
                          onChange={(e) => setTextTitle(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="label-title">{t('Mot clé')}*</div>
                      <div>
                        <InputField
                          name="keyword"
                          type="text"
                          className="form-control form-ct"
                          placeholder={t('Mot clé')}
                          value={keyword}
                          onChange={(e) => setkeyword(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row row-mb">
                    <div className="col-12 col-md-6">
                      <div className="label-title">
                        {t('URL')}
                        <span className="txt-gray">
                          {' '}
                          (
                          {t('Obligatoire en cas d’optimisation ou traduction')}
                          )
                        </span>
                      </div>
                      <div>
                        <InputField
                          name="url"
                          type="text"
                          className="form-control form-ct"
                          placeholder="https://www."
                          value={url}
                          onChange={(e) => setUrl(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="label-title">{t('Balise titre')}*</div>
                      <div>
                        <InputField
                          name="titleTag"
                          type="text"
                          className="form-control form-ct"
                          placeholder={t('Balise titre')}
                          value={titleTag}
                          onChange={(e) => setTitleTag(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div className="label-title">
                        {t('Balise meta-description')}*
                      </div>
                      <div>
                        <InputField
                          name="metaDescriptionTag"
                          type="text"
                          className="form-control form-ct"
                          placeholder={t('Balise meta-description')}
                          value={metaDescriptionTag}
                          onChange={(e) =>
                            setMetaDescriptionTag(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>

        {(textType === 'NON_TRANSLATED' || textType === 'COMBINED') && (
          <>
            <div className="mt-3">
              <div className="label-title label-text-h">
                <strong>{t('Étape')} 1</strong> -{' '}
                {t('Insérez votre gap sémantique avec l’occurrence')} :
                <CustomTooltip
                  title={
                    <div style={{ marginLeft: '10px' }}>
                      <div className="row">{t('Format souhaité')} : </div>
                      <div className="row">{t('mot-clé (fréquence)')}</div>
                      <div className="row">
                        {t('Séparez les mots-clés par un Enter .')}
                      </div>
                      <div className="row">
                        ---------------------------------
                      </div>
                      <div className="row">{t('Exemple')} :</div>
                      <div className="row">{t('mot-clé')}1 (2)</div>
                      <div className="row">{t('mot-clé')}2 (1)</div>
                      <div className="row">{t('mot-clé')}3 (1)</div>
                      <div className="row">{t('mot-clé')}4 (2)</div>
                    </div>
                  }
                  placement="top"
                >
                  <button
                    type="button"
                    className="btn-circle"
                    style={{ marginLeft: 5 }}
                  ></button>
                </CustomTooltip>
              </div>
            </div>
            <div className="row">
              <div
                className={
                  formData?.delivered ?? false
                    ? 'col-12 col-md-3 '
                    : 'col-12 col-md-2 '
                }
                style={{ marginBottom: '15px' }}
              >
                <div className="box-wrapper">
                  <textarea
                    className=" col-12  box-list-text"
                    placeholder={
                      'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n…'
                    }
                    value={combinedKeywords}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>

              {/* <div
                className={
                  formData?.delivered ?? false
                    ? 'col-12 col-md-3 '
                    : 'col-12 col-md-2 '
                }
                style={{ marginBottom: '15px' }}
              >
                <div className="d-flex flex-column justify-content-end">
                  <div className="box-wrapper">
                    {autoKeywordsGenerationLoading ? (
                      <Loader />
                    ) : (
                      <textarea
                        className=" col-12  box-list-text-gap-regeneration"
                        placeholder={
                          'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n…'
                        }
                        value={combinedKeywords}
                        onChange={handleChange}
                      ></textarea>
                    )}
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      className="bt-gap-generation"
                      onClick={() => {
                        keywordsAutoGeneration(
                          orderId,
                          keyword,
                          orderSiloName,
                          url,
                          axiosPrivate,
                          axiosMonetoring,
                          transformData,
                          formatKeywordData,
                          extractKeywordsWithFrequency,
                          setKeywords,
                          setCombinedKeywords,
                          setCombinedKeywordsList,
                          setWebsites,
                          setErrorSuccessMsg,
                          setIsSuccess,
                          setErrorSuccess,
                          t,
                          setAutokeywordGenerationLoading
                        );
                      }}
                    >
                      {t('Récupérer le Gap')}
                    </button>{' '}
                  </div>
                </div>
              </div> */}
              <div
                className={'col-12 col-md-3 box-wrapper '}
                onClick={handleDivClick}
              >
                {isEditing ? (
                  <textarea
                    className=" col-12  box-list-text box-transparent"
                    placeholder="https//www.websites.com"
                    value={websites}
                    onChange={handleInputChange}
                    onBlur={() => setIsEditing(false)}
                    style={{ color: 'black' }}
                  />
                ) : (
                  <div>
                    <div className="header-title mb-0">
                      <Fragment>{t('Maillage Interne')}</Fragment>
                    </div>
                    {(websites?.length === 0 || !websites?.length) && (
                      <div>
                        <Fragment>{t('Inserez le maillage interne')}</Fragment>
                      </div>
                    )}
                    {websites &&
                      websites.split('\n').map((word, index) => (
                        <Fragment key={index}>
                          {(actualHtmlContent.includes(
                            replaceAmpersands(word.trim()) + '/ '
                          ) ||
                            actualHtmlContent.includes(
                              replaceAmpersands(word.trim()) + ' '
                            ) ||
                            actualHtmlContent.includes(
                              replaceAmpersands(word.trim()) + '?'
                            ) ||
                            actualHtmlContent.includes(
                              replaceAmpersands(word.trim()) + '"'
                            ) ||
                            actualHtmlContent.includes(
                              replaceAmpersands(word.trim()) + '>'
                            ) ||
                            actualHtmlContent.includes(
                              replaceAmpersands(word.trim()) + '<'
                            ) ||
                            actualHtmlContent.includes(
                              replaceAmpersands(word.trim()) + '/?'
                            ) ||
                            actualHtmlContent.includes(
                              replaceAmpersands(word.trim()) + '/"'
                            ) ||
                            actualHtmlContent.includes(
                              replaceAmpersands(word.trim()) + '/>'
                            ) ||
                            actualHtmlContent.includes(
                              replaceAmpersands(word.trim()) + '/<'
                            )) &&
                          !actualHtmlContent.includes(
                            replaceAmpersands(word.trim()) + '&'
                          ) ? (
                            <div>
                              <mark
                                style={{
                                  color: 'green',
                                  backgroundColor: '#2cce6e',
                                }}
                              >
                                {word}
                              </mark>
                              <br></br>
                            </div>
                          ) : (
                            <div>
                              <mark
                                style={{
                                  color: 'red',
                                  backgroundColor: '#ffc2c2',
                                }}
                              >
                                {word}
                              </mark>
                              <br></br>
                            </div>
                          )}
                        </Fragment>
                      ))}
                  </div>
                )}
              </div>
              <div className="col-12 col-md-3">
                <div className="box-wrapper">
                  <div className="header-title mb-0">
                    {t('Résultat sémantique')}
                  </div>
                  <div className="sub-header-title">
                    {t('Enrichissement sémantique')} :
                  </div>
                  <div className="box-graph">
                    {loading && <Loader />}
                    {analyseResult && analyseResult.semanticEnrichment >= 0 ? (
                      <div className="d-flex justify-content-center">
                        <CustomHighcharts
                          value={analyseResult.semanticEnrichment}
                        />
                      </div>
                    ) : null}
                  </div>
                  <div>
                    {analyseResult && analyseResult.semanticEnrichment ? (
                      <button
                        className="bt-submit-y"
                        style={{ margin: '0 auto' }}
                        onClick={() =>
                          DownloadOneTextWordType(formData.id, user)
                        }
                      >
                        {t('Télécharger le document')}
                      </button>
                    ) : (
                      <button
                        className="bt-submit-disabled-y"
                        style={{ margin: '0 auto' }}
                      >
                        {t('Télécharger le document')}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={
                  (!(formData?.delivered ?? false) &&
                    stuffedKeywords &&
                    Object.keys(stuffedKeywords).length > 0) ||
                  keywordStuffingLoading
                    ? 'col-12 col-md-2'
                    : 'col-12 col-md-3'
                }
              >
                <div className="box-wrapper">
                  <div className="header-title mb-0">
                    {t('Mots clés manquants')} :
                  </div>
                  <br />
                  <div className="box-list-text box-motcle">
                    {loading && <Loader />}
                    <ul>
                      {formData &&
                      analyseResult &&
                      analyseResult.greenWordsByFreq &&
                      analyseResult.greenWordsByFreq
                        .greenWordsWithRemainingFreq &&
                      Object.keys(
                        analyseResult.greenWordsByFreq
                          .greenWordsWithRemainingFreq
                      ).length > 0 ? (
                        <div>
                          <b>
                            {t('Ajout de mots-clés avec la fréquence restante')}{' '}
                            :{' '}
                          </b>
                          <p>
                            {Object.keys(
                              analyseResult.greenWordsByFreq
                                .greenWordsWithRemainingFreq
                            ).map((item, key) => (
                              <li key={key}>
                                {' '}
                                <span>
                                  {' '}
                                  {item} (
                                  <span
                                    style={{
                                      textDecorationLine: 'line-through',
                                    }}
                                  >
                                    {list[item]}
                                  </span>
                                  ,
                                  {
                                    analyseResult.greenWordsByFreq
                                      .greenWordsWithRemainingFreq[item]
                                  }
                                  ){' '}
                                </span>{' '}
                              </li>
                            ))}
                          </p>
                        </div>
                      ) : null}
                      {analyseResult &&
                      analyseResult.remainingKeywords &&
                      Object.keys(analyseResult.remainingKeywords).length >
                        0 ? (
                        <div>
                          <b>{t('Mots clés restants')}:</b>
                          <p>
                            {Object.keys(analyseResult.remainingKeywords).map(
                              (item, key) => (
                                <li key={key}>
                                  {' '}
                                  <span>
                                    {' '}
                                    {item} (
                                    {analyseResult.remainingKeywords[item]}){' '}
                                  </span>{' '}
                                </li>
                              )
                            )}
                          </p>
                        </div>
                      ) : null}
                    </ul>
                  </div>
                </div>
              </div>
              {((!(formData?.delivered ?? false) &&
                stuffedKeywords &&
                Object.keys(stuffedKeywords).length > 0) ||
                keywordStuffingLoading) && (
                <div className="col-12 col-md-2">
                  <div className="box-wrapper">
                    <div className="header-title mb-0">
                      {t('Mots clés Bourrées')} :
                    </div>
                    <br />
                    <div className="box-list-text box-motcle">
                      {keywordStuffingLoading && <Loader />}
                      <ul>
                        {!keywordStuffingLoading &&
                        stuffedKeywords &&
                        Object.keys(stuffedKeywords).length > 0 ? (
                          <div>
                            <p>
                              {stuffedKeywords.map((item) => {
                                const tfPercentage = item['tfPercentage'] * 100;
                                let colorClass;

                                if (tfPercentage > 2) {
                                  colorClass = 'keywordStuffedRed';
                                } else if (
                                  tfPercentage > 1.5 &&
                                  tfPercentage <= 2
                                ) {
                                  colorClass = 'keywordStuffedOrange';
                                } else {
                                  colorClass = 'keywordStuffedYellow';
                                }

                                return (
                                  <li key={item['word']} className={colorClass}>
                                    <span> {item['word']} </span> (
                                    <span>{tfPercentage.toFixed(2)}% </span>)
                                  </li>
                                );
                              })}
                            </p>
                          </div>
                        ) : (
                          <b className="keywordStuffedGreen">
                            {t('Recherche de mots clés à haute densité')}...
                          </b>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        {(formData?.globalRevisionSent || formData?.revisionSent) &&
          formData?.revisionText && (
            <RevisionDetails
              t={t}
              textId={formData.id}
              revisionRequestAt={formData?.revisionRequestAt}
              revisionText={formData?.revisionText}
              revisionFileName={formData?.revisionFileName}
            />
          )}

        <div className="row mt-2">
          {isPremium || hasInitialTextContent || !formData ? (
            <div className="col-12 col-md-6">
              <div>
                <div className="label-title label-text-h">
                  <strong>
                    {t('Étape')} {textType === 'TRANSLATED' ? '1' : '2'}
                  </strong>{' '}
                  - {t('Coller le texte initial dans le champs ci-dessous')}
                  <span className="txt-gray">
                    : (
                    {textType === 'TRANSLATED'
                      ? t('obligatoire')
                      : t('optionel')}
                    )
                  </span>
                </div>

                <div className="box-wrapper-editor">
                  <CustomEditor
                    isReadOnly={
                      !(
                        isTextFieldDisabled ||
                        (formData && !formData.activeInitialText ? true : false)
                      )
                    }
                    content={initialHtmlContent || ''}
                    onInit={(value, editor) =>
                      setInitialTextWordCount(
                        editor.plugins.wordcount.getCount()
                      )
                    }
                    onChange={(value, editor) => {
                      setInitialHtmlContent(value);
                      let textValue = editor.getContent({ format: 'text' });
                      setInitialTextContent(textValue || '');

                      setInitialTextWordCount(
                        editor.plugins.wordcount.getCount()
                      );
                    }}
                    t={t}
                  />
                </div>
              </div>
            </div>
          ) : null}

          <div className={`col-12 col-md-${colWidth}`}>
            <div>
              <div className="label-title label-text-h">
                <strong>
                  {t('Étape')} {textType === 'TRANSLATED' ? '2' : stepNumber}
                </strong>
                - {t('Ajouter ou compléter ci-dessous votre contenu')} :
              </div>

              <div className="box-wrapper-editor">
                <CustomEditor
                  isReadOnly={
                    !(isDelivered || isRevisionDone || formData?.validated)
                  }
                  content={
                    orderType === 'SMART_WITH_REVIEW'
                      ? actualTextContent
                      : actualHtmlContent
                  }
                  onInit={handleEditorInit}
                  onChange={(value, editor) => {
                    let textValue = editor.getContent({ format: 'text' });
                    // if (isRevisionSent || isDraft) {
                    //   autoSaveText(
                    //     textValue,
                    //     value,
                    //     formData,
                    //     initialHtmlContent,
                    //     initialTextContent,
                    //     SavingState,
                    //     setSaving,
                    //     setTimer,
                    //     timer,
                    //     actualTextContent,
                    //     orderType
                    //   );
                    // }

                    if (orderType !== 'PREMIUM') {
                      setActualHtmlContent(value);
                      setActualTextContent(value);
                      setAnalyseText(textValue);
                    } else {
                      setActualHtmlContent(value);
                      setActualTextContent(textValue);
                      //setAnalyseText(textValue);
                      checkIfAllLinesGreen(value, websites);
                    }
                  }}
                  t={t}
                  orderType={orderType}
                />
              </div>
            </div>
            {/* <AutoSaveDisplay saving={saving} /> */}
          </div>
        </div>
        <div className="row ">
          <div className="col-12 col-md-6">
            <div className="box-botom-flex">
              <div style={{ paddingLeft: 10, paddingTop: 10 }}>
                {textType !== 'TRANSLATED' && (
                  <span>
                    {t('Analyses restantes')}:
                    <span style={{ color: color }}>
                      {''} {analyseCount}/{limit}
                    </span>
                  </span>
                )}
                <div>
                  <div className="box-list-dropdown">
                    {t('Langue')}:
                    <div
                      className="dropdown"
                      style={{ width: 170, marginLeft: 5 }}
                    >
                      <button
                        className="btn dropdown-toggle dropdown-custom w-100"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {t(capitalize(language.language.toLowerCase()))}
                        <span>
                          <ChevronDownIcon />
                        </span>
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        {' '}
                        {ANALYSELANGAUGESLIST.map((item, index) => (
                          <li key={index}>
                            <button
                              className="dropdown-item"
                              onClick={() => setLanguage(item)}
                            >
                              {t(
                                capitalize(item.language.toLowerCase())
                              ).toUpperCase()}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {isNotTranslation ? (
                <button
                  className={
                    isSaveDisabled ? 'bt-submit-disabled-y' : 'bt-submit-y'
                  }
                  type="submit"
                  name="action"
                  value="Save"
                  disabled={isSaveDisabled}
                >
                  {t('Sauvegarder')}
                </button>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div>
              {!isLoading ? (
                <div className="box-detail-text">
                  {textType === 'NON_TRANSLATED' && (
                    <div>
                      {t('Nombre total de mots (existants et ajoutés)')} :
                      {actualTextWordCount}{' '}
                    </div>
                  )}
                  <div>
                    {t('Nombre de mot à ajouter')} (Min/Max) :{minWords}/
                    {maxWords}
                  </div>
                  <div>
                    {t('Nombre total de mots ajoutés')} :
                    {textType === 'NON_TRANSLATED' ? (
                      <DisplayTextAmountColorAndCheckValidity
                        formData={formData}
                        initialText={initialTextContent}
                        initialTextWordCount={initialTextWordCount}
                        actualTextWordCount={actualTextWordCount}
                        minWords={minWords}
                        maxWords={maxWords}
                        setValid={(res) => setValidTextNbr(res)}
                        textType={textType}
                      />
                    ) : (
                      <span
                        style={{
                          color:
                            actualTextWordCount > maxWords
                              ? '#FF8D4B'
                              : actualTextWordCount < minWords
                              ? '#EE3900'
                              : '#3c3c3c',
                        }}
                      >
                        {actualTextWordCount}
                      </span>
                    )}
                  </div>
                </div>
              ) : (
                ''
              )}

              <div className="d-flex mb-2">
                {formData &&
                (formData.revisionSent || formData.globalRevisionSent) ? (
                  <div className="d-inline-flex">
                    <span className="me-2">
                      {isSendRevisionDisabled ? (
                        <button className="bt-submit-disabled-y">
                          {t('Enregistrer et informer client')}
                        </button>
                      ) : (
                        <button
                          className="bt-submit-y"
                          onClick={() => sendRevision()}
                        >
                          {t('Enregistrer et informer client')}
                        </button>
                      )}
                    </span>
                    <span className="me-2">
                      <AnalyseText
                        orderType={orderType}
                        keywords={combinedKeywords}
                        initialText={
                          textType === 'NON_TRANSLATED'
                            ? initialTextContent
                            : ''
                        }
                        text={
                          orderType === 'PREMIUM'
                            ? actualTextContent
                            : analyseText
                        }
                        content={
                          orderType === 'PREMIUM'
                            ? actualHtmlContent
                            : actualTextContent
                        }
                        limit={limit}
                        analysis={analysis}
                        setContent={(value) => {
                          if (orderType === 'PREMIUM') {
                            setActualHtmlContent(value);
                          } else {
                            setActualTextContent(value);
                          }
                        }}
                        language={language}
                        setList={setList}
                        setResult={(value) => setAnalyseResult(value)}
                        setLoading={(value) => setLoading(value)}
                      />
                    </span>
                  </div>
                ) : (
                  <div className="d-flex">
                    {' '}
                    {GOVERNED_WRITERS_WITH_VALIDATION.includes(user?.mail) && (
                      <div style={{ flex: 1 }}>
                        {formData &&
                        formData.delivered &&
                        !formData.validated ? (
                          <button
                            className="bt-submit-y me-2"
                            style={{ background: '#43B929', color: 'white' }}
                            type="submit"
                            name="action"
                            value={'ValidateText'}
                          >
                            {t('Valider le texte')}
                          </button>
                        ) : (
                          <>
                            <button className="bt-submit-disabled-y me-2">
                              {t('Valider le texte')}
                            </button>
                          </>
                        )}
                      </div>
                    )}
                    <div style={{ flex: 1 }}>
                      {disabledForm || !allWebsitesArePresent ? (
                        <button className="bt-submit-disabled-y me-2">
                          {t('Bon pour livraison')}
                        </button>
                      ) : (
                        <button
                          className="bt-submit-y me-2"
                          style={{ background: '#43B929', color: 'white' }}
                          type="submit"
                          name="action"
                          value={
                            textType !== 'TRANSLATED'
                              ? 'KeywordStuffingCall'
                              : 'DeliverText'
                          }
                        >
                          {t('Bon pour livraison')}
                        </button>
                      )}
                    </div>
                    <div>
                      <div className="d-inline-flex">
                        {textType !== 'TRANSLATED' && (
                          <>
                            {!formData ||
                            (formData &&
                              !isEmpty(formData.url) &&
                              !isEmpty(formData.initialTextContent)) ? (
                              <span className="me-2">
                                <button
                                  className="bt-submit-y"
                                  onClick={() => {
                                    setShowComparison(true);
                                  }}
                                >
                                  {t('Comparer')}
                                </button>{' '}
                              </span>
                            ) : (
                              ''
                            )}

                            <span className="me-2">
                              <AnalyseText
                                orderType={orderType}
                                keywords={keywords}
                                initialText={
                                  textType === 'NON_TRANSLATED'
                                    ? initialTextContent
                                    : ''
                                }
                                text={
                                  orderType === 'PREMIUM'
                                    ? actualTextContent
                                    : analyseText
                                }
                                content={
                                  orderType === 'PREMIUM'
                                    ? actualHtmlContent
                                    : actualTextContent
                                }
                                setContent={(value) => {
                                  if (orderType === 'PREMIUM') {
                                    setActualHtmlContent(value);
                                  } else {
                                    setActualTextContent(value);
                                  }
                                }}
                                limit={limit}
                                analysis={analysis}
                                language={language}
                                setList={setList}
                                setResult={(value) => setAnalyseResult(value)}
                                setLoading={(value) => setLoading(value)}
                              />
                            </span>
                          </>
                        )}

                        <span>
                          {(formData && formData.delivered) ||
                          (!initialTextWordCount &&
                            textType === 'TRANSLATED') ? (
                            <button
                              className="bt-submit-disabled-y"
                              type="submit"
                            >
                              {t('Brouillon')}
                            </button>
                          ) : (
                            <button
                              className="bt-submit-y"
                              type="submit"
                              name="action"
                              value="PutToDraft"
                              disabled={isFetchLoading}
                            >
                              {t('Brouillon')}
                            </button>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Form>

      <div className="row"></div>
      <ComparisonModal
        open={showComparison}
        initialText={initialTextContent}
        text={he.decode(actualTextContent)}
        handleClose={() => setShowComparison(false)}
      />
      <ConfirmationModal
        open={confirmation}
        message={confirmationMsg}
        handleClose={() => setConfirmation(false)}
        executeAction={handleSave}
      />
      <KeywordStuffingModal
        open={showKeywordStuffingModal}
        title={keywordStuffingModalTitle}
        message={keywordStuffingModalMsg}
        stuffedKeywords={keywordStuffingModalData}
        showButtonClose={keywordStuffingModalCloseBtn}
        handleClose={() => setShowKeywordStuffingModal(false)}
        executeAction={revisionKeywordStuffing}
      ></KeywordStuffingModal>

      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => {
          setErrorSuccess(false);
          if (
            errorSuccessMsg ===
            KeywordStuffingModalPrompts.demandeRevisionKeywordStuffing
          ) {
            dispatch(fetchWriterOrderDisplay(orderId));
            dispatch(fetchWriterTextOrderDisplay(orderId));
            dispatch(fetchAllOrders());
            history.goBack();
          }
        }}
        success={isSuccess}
        isTextDelivered={isTextDelivered}
        isAllTextesDelivered={
          orderTextsList.length === 0
            ? false
            : orderTextsList.every((text) => text.delivered)
        }
        remainingTextAmount={order && order.textAmount - orderTextsList.length}
        //orderTextsList={orderTextsList}
        analyseCount={analyseCount}
        resetFields={resetFields}
        orderId={orderId}
        orderType={orderType}
        showCreditButton={showCreditButton}
        executeAction={requestIncreaseAnalysis}
        toggle={() => setErrorSuccess(!errorSuccess)}
        user={user}
        textType={order.textType}
        imageUrl={errorSuccessImg}
      />
    </div>
  );
}

export default WriterCreateText;
